/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, shape, arrayOf, node, func,
    bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useDispatch } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ctaStyle from './CTA.module.css';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const CTA = ({
    children, data, isPersonalized, trackClickThrough,
}) => {
    useStyles(ctaStyle);
    let ctaArray = data;
    if (!Array.isArray(ctaArray)) {
        ctaArray = [data];
    }
    if (!ctaArray.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'UniversalTemplate/SubComponents/CTA/CTA.js',
            message: 'No data available to render component',
        });
    }
    const dispatch = useDispatch();
    useStyles(ctaStyle);
    const handleClick = (item) => {
        dispatch(trackEvent({
            eventCategory: item.tracking_event_category || '',
            eventAction: item.tracking_event_action || '',
            eventLabel: item.tracking_event_label || '',
        }));
        if (isPersonalized) trackClickThrough();
    };

    return (
        <div className={`${ctaStyle.buttonContainer} ${children ? ctaStyle.buttonContainerWithChildren : ''}`}>
            {ctaArray.map((item) => {
                let presentationClass = '';
                switch (item?.presentation) {
                    case 'secondary':
                        presentationClass = ctaStyle.secondary;
                        break;
                    case 'primary':
                        presentationClass = ctaStyle.primary;
                        break;
                    case 'tertiary':
                        presentationClass = ctaStyle.tertiary;
                        break;
                    default: presentationClass = ctaStyle.text;
                }
                let themeClass = '';
                switch (item?.theme) {
                    case 'light':
                    case '#2f2f2f':
                    case 'onDark':
                        themeClass = ctaStyle.light;
                        break;
                    case 'dark':
                    case '#ffffff':
                    case 'onLight':
                        themeClass = ctaStyle.dark;
                        break;
                    default: themeClass = '';
                }
                const childrenCss = children ? ctaStyle.withChildren : '';
                const buttonClassName = `${ctaStyle.ctaButton} ${presentationClass} ${themeClass} ${childrenCss}`;
                return (
                    item.link?.href
                        ? (
                            <LinkOrATag
                                isTrackEventEnabled
                                href={item?.link.href}
                                trackingEventCategory={item?.tracking_event_category}
                                trackingEventAction={item?.tracking_event_action}
                                trackingEventLabel={item?.tracking_event_label}
                                className={buttonClassName}
                                noLinkOrTitle={!item?.link.href && !item.link?.title}
                                trackClickThrough={trackClickThrough}
                                isPersonalized={isPersonalized}
                            >
                                {children}
                                {<span className={`${item?.presentation === 'tertiary' ? ctaStyle.textTertiary : ''}`}>{item.link?.title}</span>}
                            </LinkOrATag>
                        )
                        : (
                            item.link?.title && (
                                <button type="button" className={`${ctaStyle.button} ${buttonClassName}`} onClick={() => handleClick(item)}>
                                    {item.link?.title}
                                </button>
                            )
                        )
                );
            })}
        </div>
    );
};

CTA.propTypes = {
    data: arrayOf(shape({
        presentation: string,
        theme: string,
        tracking_event_category: string,
        tracking_event_action: string,
        tracking_event_label: string,
        link: shape({
            title: string,
            href: string,
        }),
    })),
    children: node,
    isPersonalized: bool,
    trackClickThrough: func,
};
CTA.defaultProps = {
    data: [],
    children: null,
    isPersonalized: false,
    trackClickThrough: () => { },
};
export default CTA;
