/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { useUIDSeed } from 'react-uid';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import CTA from '../../../SubComponents/CTA/CTA';
import SixUpCss from './SixUp.module.css';

// helpers
import cmsCssClassesToString from '../../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';
import normalizeAltCopy from '../../../../../../helpers/normalizeAltCopy';
import useClickStreamCustomEventsTracking from '../../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

const SixUp = ({ data, salesforceResponse }) => {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Tiles/SixUp/SixUp.js',
            message: 'No data available to render component',
        });
    }
    const seed = useUIDSeed();
    useStyles(SixUpCss);
    const { ga_constants = {}, tile = [] } = data || {};
    const defaultParams = 'auto=webp';
    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: salesforceResponse?.campaign,
        page: { type: 'home' },
    });

    return (
        <div className={SixUpCss.container} data-testid="sixUpTiles">
            {tile.map(({
                image = {}, cta = {}, sub_heading = '', css_classes = ['text2', 'u-margin-top-14'],
            }) => (
                <div key={seed} className={`${SixUpCss.imageWrapper}`} role="presentation">
                    {cta.link?.href && (
                        <CTA
                            data={{
                                link: cta.link,
                                tracking_event_category: ga_constants?.tracking_event_category,
                                tracking_event_action: ga_constants?.tracking_event_action,
                                tracking_event_label: cta?.tracking_event_label,
                                presentation: 'tertiary',
                            }}
                            trackClickThrough={trackClickThrough}
                            isPersonalized
                        >
                            <img className={SixUpCss.image} src={`${image?.url}?${defaultParams}`} alt={normalizeAltCopy(image?.title)} loading="lazy" />
                            {sub_heading && <p className={cmsCssClassesToString(css_classes.length > 0 ? css_classes : ['text2', 'u-margin-top-14'])}>{sub_heading}</p>}
                            {/* {sub_heading && <div className={SixUpCss.subHeading}>{sub_heading}</div>} */}
                        </CTA>
                    )}
                </div>
            ))}
        </div>
    );
};

SixUp.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
SixUp.defaultProps = {
    salesforceResponse: {},
};

export default SixUp;
